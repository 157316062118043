.signatureclass {
  position: absolute;
  bottom: 27%;
  right: 49%;
}

.classcertificateleft {
  position: absolute;
  top: 11%;
  left: 3%;
}
.classcertificateright {
  position: absolute;
  left: 49%;
  top: 11%;
}
.certificateContent {
  position: absolute;
  left: 14%;
  top: 45%;
}

.certificateContentName {
  position: absolute;
  bottom: 24%;
  right: 50%;
}

.certificateContentDeg {
  position: absolute;
  bottom: 21%;
  right: 48%;
}
